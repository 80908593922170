import React, { useContext, useState } from "react";
import { Rating } from "react-simple-star-rating";
// import Background from "./components/background";
import ReviewButton from "./components/button";
import SourcePlatform from "./components/sourcePlatform";
import ContactForm from "./components/contact-form/contact-form";
import { BiArrowBack } from "react-icons/bi";
import { GlobalState } from "./data/Context";

export const settings = {
	textColor: "#ffffff",
	backgroundColor: "#ffffff",
	transparent: "transparent",
	fontColor: "#65D133",
	buttonFontColor: "#ffffff",
	starSize: 28,
	bgRadius: 8,
	submitButtonColor: "#65D133",
	submitButtonFontColor: "#ffffff",
	submitButtonFontSize: 12,
	submitButtonBorderRadius: 8,
};
function Review({ id }) {
	const [rating, setRating] = useState(0);
	const [show, setShow] = useState("review"),
		{ review, platform } = useContext(GlobalState);

	const handleRating = rate => {
		console.log(rate);
		setRating(rate);
	};

  const goBack = () => {
    setShow("review");
  };

	const submitRating = e => {
		e.preventDefault();
		if (rating < 1) {
			return;
		}
		if (rating < 4) {
			setShow("contact");
		} else {
			setShow("source");
		}
	};

	// const handleSettingsChange = (e) => {
	//   const { name, value } = e.target;
	//   console.log(name, value);
	//   setSettings({ ...settings, [name]: value });
	// };
	return (
		<div>
			{show === "review" ? (
				<div>
					<div className="body">
						<p
							className={`text-md mb-2`}
							style={{ color: review?.fontColor || settings.fontColor }}>
							{review?.subText || `Rate us`}
						</p>
						<div className="stars flex items-center gap-4 mb-4">
							{/* <FiveStar color={settings.fontColor} size={settings.starSize} /> */}
						</div>
						<div className="flex mb-4">
							<Rating onClick={handleRating} fillColor={review?.starColor} />
						</div>
						<form className="space-y-4" onSubmit={submitRating}>
							{/* <div>
								<textarea
									type="text"
									placeholder="Message here"
									rows={1}
									className="border rounded-md w-full p-4"
								/>
							</div> */}
							{/* <div className="flex items-center gap-x-12">
								<span className="flex items-center gap-2">
									<input type="radio" name="review" />
									<label className="text-xs font-medium">
										set as private review
									</label>
								</span>
								<span className="flex items-center gap-2">
									<input type="radio" name="review" />
									<label className="text-xs font-medium">
										set as public review
									</label>
								</span>
							</div> */}
							<div className="flex justify-center gap-8 mt-2">
								<ReviewButton
									bgColor={review?.submitColor || settings.fontColor}
									fontColor={
										review?.buttonFontColor || settings.buttonFontColor
									}
									fontSize={
										review?.submitButtonFontSize ||
										settings.submitButtonFontSize
									}
									borderRadius={
										review?.submitButtonBorderRadius ||
										settings.submitButtonBorderRadius
									}
									title={review?.submitText}
								/>
							</div>
						</form>
					</div>
					<div className="foot mt-4">
						<p className="text-[#0E2D00] text-center">
							widget by <strong>FlashReview</strong>
						</p>
					</div>
				</div>
			) : show === "source" ? (
				<SourcePlatform state={platform} />
			) : (
				<ContactForm id={id} />
			)}
			{show !== "review" && (
				<div className="flex justify-end">
					<button className="flex items-center gap-2" onClick={goBack}>
						<span>
							<BiArrowBack />
						</span>
						back
					</button>
				</div>
			)}
		</div>
	);
}

export default Review;
