import ExportCard from "./export-card";

const SourcePlatform = ({ state }) => {
	// const sources = [
	//   { id: 1, src: "Google", img: "google.png" },
	//   { id: 2, src: "play-store", img: "play-store.png" },
	//   { id: 3, src: "slack", img: "slack.png" },
	//   { id: 4, src: "yelp", img: "yelp.png" },
	// ];
	return (
		<div>
			<div className="grid md:grid-cols-3 gap-4 my-4">
				{state.map(source => (
					<ExportCard
						key={source._id}
						title={source.platform?.name}
						item={source}
						toggle={
							source?.platform?.name?.includes("google")
								? `https://www.google.com/maps/place/?q=place_id=${source?.userID}`
								: source?.userLink
						}
					/>
				))}
			</div>
		</div>
	);
};

export default SourcePlatform;
