const ReviewButton = ({
  bgColor,
  fontColor,
  fontSize,
  borderRadius,
  ...otherProps
}) => {
  return (
		<button
			className={`text-[${fontSize}px] h-10 px-8 cursor-pointer`}
			type="submit"
			style={{
				borderRadius: `${borderRadius}px`,
				background: bgColor,
				color: fontColor,
			}}
			{...otherProps}>
			{otherProps?.title || `Submit`}
		</button>
	);
};

export default ReviewButton;
