import axios from "axios";
import { createContext, useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";

export let GlobalState = createContext(),
	COUNT = "_FLASH_REVIEW_COUNT_",
	DATE = "_FLASH_REVIEW_DATE_";

const DataProvider = ({ children }) => {
	let [platform, setPlatform] = useState(null),
		[review, setReview] = useState(null),
		[error, setError] = useState(),
		[isOpen, setIsOpen] = useState(false),
		toggle = () => {
			let findCount = localStorage.getItem(COUNT);

			if (findCount) localStorage.setItem(COUNT, 1 + Number(findCount));
			else localStorage.setItem(COUNT, 1);
			localStorage.setItem(DATE, moment());
			setIsOpen(false);
		};
	let handleCapitalize = word => {
		let splitter = word.trim().split(" ");
		let firstCap = splitter[0].split("");
		let remaining = splitter.slice(1, splitter.length).join(" ");

		let firstCapOne = firstCap[0].toUpperCase();
		let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

		let joinFirst = `${firstCapOne}${firstCapTwo}`;

		return `${joinFirst} ${remaining}`;
	};

	let numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const addNewUserPlatform = async id => {
		try {
			let res = await axios.get(`/api/v1/review/widget-popup?reviewId=${id}`);
			setPlatform(res?.data?.platform || res?.data);
			setReview(res?.data?.data || res?.data);
		} catch (err) {
			if (err) console.log(err.response?.data?.data, { err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
		}
	};

	useEffect(() => {
		if (review) {
			let findDate = localStorage.getItem(DATE),
				findCount = localStorage.getItem(COUNT);
			if (findDate) {
				if (
					moment(findDate).format("DD/MM/YYYY") ===
					moment().format("DD/MM/YYYY")
				) {
					if (findCount) {
						if (Number(findCount) >= Number(review?.frequency)) {
						} else {
							setTimeout(() => {
								setIsOpen(true);
							}, (review?.time || 1) * 1000);
						}
					} else {
						localStorage.setItem(COUNT, 0);
						setTimeout(() => {
							setIsOpen(true);
						}, (review?.time || 1) * 1000);
					}
				} else {
					localStorage.setItem(COUNT, 0);
					setTimeout(() => {
						setIsOpen(true);
					}, (review?.time || 1) * 1000);
				}
			} else {
				localStorage.setItem(COUNT, 0);
				setTimeout(() => {
					setIsOpen(true);
				}, (review?.time || 1) * 1000);
			}
		}
	}, [review]);

	const state = {
		handleCapitalize,
		numberWithCommas,
		platform,
		review,
		addNewUserPlatform,
		error,
		setError,
		isOpen,
		toggle,
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
