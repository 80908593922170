import { ClipLoader } from "react-spinners";
import { settings } from "../review";
import { useContext } from "react";
import { GlobalState } from "../data/Context";

const ModalComponents = ({ isOpen, toggle, title, children, bgColor }) => {
	const { review } = useContext(GlobalState);
	return (
		<>
			<div
				id="demo-modal"
				className={`modal ${isOpen ? "open" : ""}`}
				style={{
					zIndex: "999",
				}}>
				<div
					className="modal__content"
					style={{ background: settings?.bgColor }}>
					<h3
						className="mb-6 modal__titl font-semibold text-2xl"
						style={{ color: review?.fontColor || settings?.fontColor }}>
						{review?.headline || title || `Review our services`}
					</h3>
					<div
						className="overflow-y-auto p-2"
						style={{
							maxHeight: "75vh",
						}}>
						{children}
					</div>
					<span className="modal__close" onClick={toggle}>
						&times;
					</span>
				</div>
			</div>
		</>
	);
};

export default ModalComponents;

export const Buttons = ({
	type,
	loading,
	width,
	css,
	title,
	children,
	onClick,
	loadCss,
	style,
	disabled,
	...restProps
}) => {
	return (
		<button
			disabled={loading || disabled}
			type={type || "submit"}
			style={style || {}}
			onClick={onClick ? onClick : () => {}}
			className={`btn ${css || ""} capitalize
			 flex items-center justify-center ${
					width || "w-100"
				} inline-block w-60 rounded ${
				restProps?.bg ||
				" hover:bg-main bg-main focus:bg-main active:bg-main btn-primary1"
			} ${
				restProps?.color || " text-white"
			} px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]`}
			{...restProps}>
			{children}
			<span className={loading ? "me-2" : ""}>{title || "log in"}</span>
			{loading && <ClipLoader color={loadCss || "white"} size={16} />}
		</button>
	);
};
