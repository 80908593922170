import { useContext, useMemo, useState } from "react";
import DataProvider, { GlobalState } from "./data/Context";
import Review from "./review";
import { SetDefaultHeaders } from "./data/Config";
import ModalComponents from "./components/ModalComponents";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import { ToastContainer } from "react-toastify";

SetDefaultHeaders();

function App() {
	return (
		<DataProvider>
			<ToastContainer position="bottom-center" />
			<AppContainer />
		</DataProvider>
	);
}

export default App;

export function AppContainer() {
	let { addNewUserPlatform, isOpen, toggle } =
			useContext(GlobalState),
		[id, setId] = useState("");

	useMemo(() => {
		let id = window.location.pathname.slice(
			window.location.pathname?.indexOf("/reviewId=") + 10
		);
		if (id?.length === 10) {
			addNewUserPlatform(id);
			setId(id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ModalComponents isOpen={isOpen} toggle={toggle}>
				<Review id={id} />
			</ModalComponents>
		</>
	);
}
